import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { LoginService } from "../../services/login.service";
import { ToastrService } from "ngx-toastr";
import { CommonService } from "../../services/common.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { environment } from "../../../environments/environment";
import { ChatService } from "../../services/chat.service";
import { ActivatedRoute } from "@angular/router";

import { error } from "console";

declare var $: any;
declare const register: any;
declare const call: any;
declare const statusRegister: any;
@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  DEFAULT_PWD = "ABC123";
  data: any = {};
  emailId: string = "";
  emailPattern: RegExp = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i;
  loginForm: FormGroup;
  apires: any;
  submitted = false;
  fieldTextType: boolean | undefined;

  WebSocket_URL = environment.WebSocket_URL;
  OpenVidu_URL = environment.OpenVidu_URL;
  roleId: any;
  userData: any;
  isBool: boolean = false;
  aluminiLogin: boolean = false;
  isUrlBasedLogo: boolean = false;
  constructor(
    private userService: LoginService,
    private chatService: ChatService,
    private route: Router,
    private toastr: ToastrService,
    private commonService: CommonService,
    private fb: FormBuilder,
    private activeRoute: ActivatedRoute
  ) {
    // this.getCompanyDetails();
    let findUrl: any = location.href;
    if (
      findUrl == "http://localhost:4200/login" ||
      findUrl == "https://oukdevapplicant.samvaadpro.com/login" ||
      findUrl == "https://oukqaapplicant.samvaadpro.com/login" ||
      findUrl == "https://oukadmissionsdev.konza.go.ke/login" ||
      findUrl == "https://admissions.ouk.ac.ke/login" ||
      findUrl == "https://oukdevapplicanttest.samvaadpro.com/login"
    ) {
      this.isBool = true;
    } else if (
      // findUrl == "http://localhost:4200/login" ||
      findUrl == "https://oukalumnidev.samvaadpro.com/login" ||
      findUrl == "https://oukalumniqa.samvaadpro.com/login"
    ) {
      this.aluminiLogin = true;
    }
    localStorage.clear();

    this.checkUrlLogo(findUrl);
  }

  checkUrlLogo(url: string) {
    const urlObj = {
      dhanushFinance: "https://finance.dhanushinfotech.com/login",
      financeDemo: "https://financedemo.dhanushinfotech.com/login",
      nwhQa: "https://nwhfinanceqa.dhanushinfotech.com/login",
      nwhPreprod: "https://nwhfinancepreprod.dhanushinfotech.com/login",
      nwhProd: "https://finance.himscmchbhopal.com/login",
    };
    const { nwhQa, nwhPreprod, nwhProd, dhanushFinance, financeDemo } = urlObj;
    if (
      dhanushFinance === url ||
      financeDemo === url ||
      nwhQa === url ||
      nwhPreprod === url ||
      nwhProd === url
    ) {
      this.isUrlBasedLogo = true;
      localStorage.setItem("isUrlBasedLogo", "true");
    } else {
      this.isUrlBasedLogo = false;
      localStorage.setItem("isUrlBasedLogo", "false");
    }
  }

  ngOnInit(): void {
    const bo: any = document.body;
    bo.style = "";
    // localStorage.setItem('openpages', Date.now().toString());

    // const onLocalStorageEvent = (e: StorageEvent) => {
    //   if (e.key === 'openpages') {
    //     // Listen if anybody else is opening the same page
    //     localStorage.setItem('page_available', Date.now().toString());
    //   }
    //   if (e.key === 'page_available') {
    //     alert('One more page already open');
    //   }
    // };

    // window.addEventListener('storage', onLocalStorageEvent);

    this.loginForm = this.fb.group({
      userName: ["", Validators.required],
      password: ["", Validators.required],
    });
    // ["userName", "password"].forEach(e => {
    //   const element = (document.getElementById('userName') as HTMLInputElement);
    //   element.setAttribute("autocapitalize", "off");
    //   element.setAttribute("autocomplete", "off");
    //   element.setAttribute("autocorrect", "off");
    //   element.setAttribute("spellcheck", "off");
    // });
    setTimeout(() => this.companyDetails(), 10);
    this.signFromHIMS();
  }

  get f() {
    return this.loginForm.controls;
  }

  signFromHIMS() {
    this.activeRoute.queryParams.subscribe((params) => {
      if (Object.keys(params).length) {
        const data = params["Finance"];
        this.loginHIMS(data);
      }
    });
  }

  loginHIMS(data) {
    this.commonService.activateSpinner();
    this.commonService
      .postCall(`Account/FinanceLogin`, { token: data })
      .subscribe((res: any) => {
        if (res.status) {
          const obj = JSON.parse(atob(res.data));
          this.loginForm.patchValue({
            userName: obj.username,
            password: obj.password,
          });
          this.registerUser();
        } else {
          this.toastr.warning(res.message);
        }
        this.commonService.deactivateSpinner();
      });
    this.commonService.deactivateSpinner();
  }

  registerUser() {
    this.submitted = true;
    let user = this.loginForm.getRawValue();
    if (user.userName.trim().length == 0) {
      (document.getElementById("userName") as HTMLInputElement).focus();
    }
    if (user.password.trim().length == 0) {
      (document.getElementById("password") as HTMLInputElement).focus();
    }

    if (this.loginForm.invalid) {
      this.toastr.error("Please enter username or password");
      return;
    }
    this.commonService.activateSpinner();
    this.userService.login(user).subscribe(
      (succ) => {
        let userData: any = succ ?? {};
        this.userData = succ;
        this.commonService.deactivateSpinner();

        if (
          userData.ROLEID == localStorage.getItem("RoleId") &&
          localStorage.getItem("isLoggedIn") == "true"
        ) {
          return this.toastr.warning("One more page already open");
        }

        localStorage.setItem(
          "MoodleUserName",
          userData?.moodle_adminloginusername
        );
        let roleId = atob(userData.ROLEID);
        if (roleId == "1") {
          localStorage.setItem("MoodleTkn", userData?.moodle_adminlogintoken);
        } else {
          localStorage.setItem("MoodleTkn", userData.moodle_token);
        }

        this.commonService.userId = userData.USERID;
        sessionStorage.setItem("UserId", userData.USERID);
        sessionStorage.setItem("Username", userData.FIRSTNAME);
        sessionStorage.setItem("TenantCode", userData.TENANTCODE);
        sessionStorage.setItem("RoleId", userData.ROLEID);
        localStorage.setItem("isLoggedIn", "true");
        localStorage.setItem("RoleName", userData.ROLE_NAME);
        localStorage.setItem("tkn", userData?.token);
        localStorage.setItem("accsTkn", userData.is_tokenexist);
        localStorage.setItem("IsKuccsps", userData.is_kuccps);
        localStorage.setItem("IsFirstLogin", userData.is_applfirstlogin);
        localStorage.setItem("tnt_logo", userData.tnt_logo);
        localStorage.setItem("MoodleUserId", userData.moodle_userid);
        sessionStorage.USERNAME = userData.USERNAME;
        sessionStorage.FullName =
          `${userData.FIRSTNAME} ${userData.LASTNAME}`.toUpperCase();
        sessionStorage.profileImage = (userData.USERIMAGE || "data:,").replace(
          "data:,",
          "https://i.pinimg.com/736x/89/90/48/899048ab0cc455154006fdb9676964b3.jpg"
        );
        sessionStorage.userData = JSON.stringify(userData);
        sessionStorage.tntName = userData.tnt_name;
        const title = +userData.ROLEID == 3 ? "" : "Prof. ";
        let usrNme: string =
          title + `${userData.FIRSTNAME} ${userData.LASTNAME}`.toUpperCase();
        usrNme = usrNme.length > 25 ? usrNme.substring(0, 23) + ".." : usrNme;
        sessionStorage.usrNme = usrNme;
        sessionStorage.isLogIn = true;
        (<HTMLInputElement>document.getElementById("user")).value = usrNme;

        localStorage.setItem("UserId", userData.USERID);
        localStorage.setItem("Username", userData.FIRSTNAME);
        localStorage.setItem("TenantCode", userData.TENANTCODE);
        localStorage.setItem("RoleId", userData.ROLEID);
        sessionStorage.setItem("refreshedRole", btoa(userData.ROLEID));
        localStorage.setItem("courseId", userData.CourseId);
        localStorage.setItem("countryID", userData?.country_id);
        localStorage.setItem("Proctoring", userData.allow_proctoring);
        localStorage.setItem("RoleName", userData.ROLE_NAME);
        localStorage.setItem("CourseId", userData.CourseId);
        localStorage.setItem("UserBaseSidebarMenu", userData.is_userbasisubmit);
        localStorage.setItem("isStudent", userData.is_student);
        localStorage.setItem("SchoolId", userData.category_id);
        localStorage.setItem("DepartmentId", userData.department_id);
        localStorage.setItem("TYPE", userData.type);
        localStorage.setItem("IsApplicant", userData.is_applicant);
        localStorage.setItem(
          "ApplicantRoleId",
          userData.enrcryptedapplicantrole
        );
        localStorage.setItem("HimsUrl", userData.hims_url);
        localStorage.setItem("SMHRUrl", userData.smhr_url);
        localStorage.setItem("departmentId", userData.department_id);
        if (userData.is_tokenexist) {
          if (
            window.confirm(
              "Already you have logged in. Are you sure you want to logout and login again?"
            )
          ) {
            let payload = {
              UserID: this.userData.USERID,
              AccessToken: this.userData.token,
              tnt_code: this.userData.TENANTCODE,
            };
            this.commonService
              .postCall("Account/SetToken", payload)
              .subscribe((res: any) => {
                const {
                  TenantCode = 0,
                  UserId = 0,
                  RoleId = 0,
                  Username = "",
                } = sessionStorage;
                if (+RoleId == 2) {
                  this.commonService
                    .postCall("Courses/LoadCoursesByTrainer", {
                      USER_ID: UserId,
                      TNT_CODE: TenantCode,
                    })
                    .subscribe((rep) => {
                      if (rep) {
                        let courseList: Array<any> = rep.map((e) => ({
                          courseId: e.COURSE_ID,
                          courseName: e.COURSE_NAME,
                          teacherId: UserId,
                        }));
                        const uniqueIds: any = [];
                        courseList = courseList.filter((e: any) => {
                          const isNotExist: boolean = !uniqueIds.includes(
                            e.courseId
                          );
                          if (isNotExist) uniqueIds.push(e.courseId);
                          return isNotExist;
                        });
                        (<any>window).courseInfo = courseList;
                        (<any>window).is_login = true;
                        sessionStorage.courseList = JSON.stringify(courseList);
                        setTimeout(() => {
                          this.openVeduURLs();
                          this.webRTC_method(true, userData);
                        }, 100);
                      }
                    });
                }
                if ([2, 3].includes(+RoleId)) {
                  const userType: string = RoleId == 2 ? "Teacher" : "Student";
                  const chatobject = { TenantCode, UserId, userType, Username };
                  sessionStorage.ChatUser = JSON.stringify(chatobject);
                  this.chatService.connectSocket(
                    chatobject.TenantCode,
                    chatobject.UserId,
                    chatobject.userType,
                    chatobject.Username
                  );
                }
                this.roleId = atob(userData.ROLEID);
                if (this.roleId == 3) {
                  if (userData.is_firstlogin) {
                    this.roleChange(atob(userData.ROLEID), userData.TENANTCODE);
                    return this.route.navigate(["/HOME/studentDashboard"]);

                    // if (userData.is_applfirstlogin == true) {
                    //   return this.route.navigate(['/HOME/programs']);
                    // } else {
                    //   this.route.navigate(['/HOME/components/prnlDetailsRegForm']);
                    // }
                  } else {
                    return this.route.navigate(["/changePassword"]);
                  }
                }
                this.roleChange(atob(userData.ROLEID), userData.TENANTCODE);
                if (this.roleId == 2) {
                  return this.route.navigate(["/HOME/dashboard"]);
                }
                if (this.roleId != 3) {
                  userData.is_firstlogin
                    ? this.route.navigate(["/HOME"])
                    : this.route.navigate(["/changePassword"]);
                }
              });
          } else {
            this.route.navigate(["/login"]);
          }
        } else {
          const {
            TenantCode = 0,
            UserId = 0,
            RoleId = 0,
            Username = "",
          } = sessionStorage;
          if (+RoleId == 2) {
            this.commonService
              .postCall("Courses/LoadCoursesByTrainer", {
                USER_ID: UserId,
                TNT_CODE: TenantCode,
              })
              .subscribe((rep) => {
                if (rep) {
                  let courseList: Array<any> = rep.map((e) => ({
                    courseId: e.COURSE_ID,
                    courseName: e.COURSE_NAME,
                    teacherId: UserId,
                  }));
                  const uniqueIds: any = [];
                  courseList = courseList.filter((e: any) => {
                    const isNotExist: boolean = !uniqueIds.includes(e.courseId);
                    if (isNotExist) uniqueIds.push(e.courseId);
                    return isNotExist;
                  });
                  (<any>window).courseInfo = courseList;
                  (<any>window).is_login = true;
                  sessionStorage.courseList = JSON.stringify(courseList);
                  setTimeout(() => {
                    this.openVeduURLs();
                    this.webRTC_method(true, userData);
                  }, 100);
                }
              });
          }
          if ([2, 3].includes(+RoleId)) {
            const userType: string = RoleId == 2 ? "Teacher" : "Student";
            const chatobject = { TenantCode, UserId, userType, Username };
            sessionStorage.ChatUser = JSON.stringify(chatobject);
            this.chatService.connectSocket(
              chatobject.TenantCode,
              chatobject.UserId,
              chatobject.userType,
              chatobject.Username
            );
          }
          this.roleId = atob(userData.ROLEID);
          if (this.roleId == 3) {
            if (userData.is_firstlogin) {
              this.roleChange(atob(userData.ROLEID), userData.TENANTCODE);
              return this.route.navigate(["/HOME/studentDashboard"]);

              // if (userData.is_applfirstlogin == true) {
              //   return this.route.navigate(['/HOME/programs']);
              // } else {
              //   this.route.navigate(['/HOME/components/prnlDetailsRegForm']);
              // }
            } else {
              return this.route.navigate(["/changePassword"]);
            }
          }
          this.roleChange(atob(userData.ROLEID), userData.TENANTCODE);
          if (this.roleId == 2) {
            return this.route.navigate(["/HOME/dashboard"]);
          }
          if (this.roleId != 3) {
            userData.is_firstlogin
              ? this.route.navigate(["/HOME"])
              : this.route.navigate(["/changePassword"]);
          }
        }
      },
      (err) => {
        let e = err.error;
        this.commonService.deactivateSpinner();
        this.toastr.error(e?.message || "Please try again later");
      }
    );
  }
  accessToken() {
    if (
      window.confirm(
        "Already you have logged in. Are you sure you want to logout and login again?"
      )
    ) {
      let payload = {
        UserID: this.userData.USERID,
        AccessToken: this.userData.token,
        tnt_code: this.userData.TENANTCODE,
      };
      this.commonService
        .postCall("Account/SetToken", payload)
        .subscribe((res: any) => {});
    } else {
      this.route.navigate(["/login"]);
    }
  }
  roleChange(rId: any, tntCode: any) {
    this.commonService.activateSpinner();
    this.commonService
      .getCall(`PermissionRole/GetListById/${tntCode}/${rId}`)
      .subscribe(
        (res: any) => {
          localStorage.setItem("isApprove", btoa(JSON.stringify(res.data)));
          this.commonService.deactivateSpinner();
          this.getChartOfAccountInfo(tntCode);
        },
        (e) => {
          this.commonService.deactivateSpinner();
        }
      );
  }

  getChartOfAccountInfo(tntCode: any): void {
    this.activeSpinner();
    this.commonService
      .getCall(`ChartOfAccount/GetList/${tntCode}/-1`)
      .subscribe(
        (res: any) => {
          this.deactiveSpinner();
          if (res.status == true && res.data.length > 0) {
            sessionStorage.chartOfAccountId = res.data[0].cha_chartofaccount_id;
            return +sessionStorage.chartOfAccountId;
          }
        },
        (e) => {
          this.deactiveSpinner();
        }
      );
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }
  navigateToOuk() {
    window.open("https://ouk.ac.ke/");
  }
  validateEmail(event: any) {
    let val = event.target.value.trim();
    let exp = this.emailPattern;

    if (!new RegExp(exp).test(val)) {
      this.toastr.warning(
        "Invalid email credentials, please enter a valid email"
      );
      return;
    }
    this.emailId = val;

    // if (new RegExp(exp).test(val)) {
    //   // Check if there are numbers after "@" symbol
    //   let parts = val.split('@');
    //   if (parts.length === 2 && /\d/.test(parts[1])) {
    //     this.emailId = null;
    //     this.toastr.warning('Numbers are not allowed after "@"');
    //     // this.commonService.deactivateSpinner();
    //   } else {
    //     this.emailId = val;
    //   }
    // } else {
    //   this.emailId = null;
    //   this.toastr.warning('Invalid email credentials, please enter a valid email');
    //   // this.commonService.deactivateSpinner();
    // }
    // // this.commonService.deactivateSpinner();
  }
  close() {
    this.emailId = "";
  }
  activeSpinner() {
    this.commonService.activateSpinner();
  }
  deactiveSpinner() {
    this.commonService.deactivateSpinner();
  }
  onForgotPasswordClick() {
    // this.route.navigate(['/forgotPassword']);
    this.activeSpinner();
    if (!this.emailId || !this.emailPattern.test(this.emailId)) {
      this.toastr.warning("Please enter a valid email id");
      this.emailId = "";
      this.deactiveSpinner();
      return;
    }
    let originUrl = new URL(window.location.href);
    // let baseurl = `${originUrl.protocol}//${originUrl.hostname}`;
    let payload = {
      UserName: this.emailId,
      Url: originUrl.origin,
    };
    const API = this.isBool
      ? "Account/InitialApplicantForgotPassword"
      : "Account/ForgotPassword";
    this.commonService.postCall(API, payload).subscribe(
      (res: any) => {
        this.apires = res;
        this.toastr.success(this.apires?.message);
        document.getElementById("md_close")?.click();
        this.emailId = "";
        this.deactiveSpinner();
      },
      (err: any) => {
        this.toastr.error(err.error ? err.error?.message : err.error?.message);
        this.deactiveSpinner();
      }
    );
    this.deactiveSpinner();

    // let user = this.loginForm.getRawValue();
    // if (user.userName.trim().length > 0) {
    //   const { company_id = 0 } = sessionStorage;
    //   const payload: string = `Account/ForgotPassword/${user.userName.trim()}`;

    //   this.userService.forgotPassword(payload).subscribe((res: any) => {
    //     const message: string = "Your Password has been sent to your registered email.";

    //     if (res.message == message) {
    //       this.toastr.success(message, "Login Page");
    //     }
    //     else {
    //       this.toastr.warning(res.message, "Login Page");
    //     }
    //   }, (err) => {
    //     let e = err.error;
    //     this.toastr.error(e?.text || 'Please Enter Valid Registered Email ID');

    //   });
    // }
    // else {
    //   this.toastr.error("Please enter valid email", "Login Page");
    //   (document.getElementById("userName") as HTMLInputElement).focus();
    //   this.commonService.deactivateSpinner();
    // }
    // this.commonService.deactivateSpinner();
  }

  companyDetails() {
    const { fileUrl } = environment;
    if (sessionStorage.homepageimage_path) {
      document
        .getElementById("homepageimage_path")
        ?.setAttribute(
          "src",
          `${fileUrl}${sessionStorage.homepageimage_path} `
        );
    }
  }

  gotoSignUp() {
    // const URL = (/true/).test(sessionStorage.isDomain) ? "/student-signup" : "/signup";
    this.route.navigate(["student-signup"]);

    // this.route.navigate([URL]);
  }

  openVeduURLs() {
    (<HTMLInputElement>document.getElementById("ovUrl")).value =
      this.OpenVidu_URL;
    (<HTMLInputElement>document.getElementById("ovKey")).value = "dipl123";
    (<HTMLInputElement>document.getElementById("apUrl")).value =
      this.WebSocket_URL;

    (<any>window).OPENVIDU_SERVER_URL = this.OpenVidu_URL;
    (<any>window).OPENVIDU_SERVER_SECRET = "dipl123";
    (<any>window).vidmed_url = `${this.WebSocket_URL}telecon/`;

    sessionStorage.OpenVidu_URL = this.OpenVidu_URL;
    sessionStorage.WebSocket_URL = this.WebSocket_URL;
  }

  webRTC_method(isTeacher: boolean = true, userData: any) {
    if (!userData) return;
    const userInfo = {
      toId: "",
      fromId: `tea_${userData.USERID}`,
      name: userData.FIRSTNAME,
      userType: 1,
      complaints: "",
      myId: userData.USERID,
      usrTkn: "",
    };

    // if (isTeacher)
    //   [userInfo.toId, userInfo.fromId, userInfo.name, userInfo.userType, userInfo.complaints, userInfo.myId] = ['', `tea_${userData.USERID}`, userData.FIRSTNAME, 1, '', userData.USERID];

    userInfo.usrTkn = `org_${userData.TENANTCODE}_${userData.TNT_NAME}_${
      userInfo.fromId
    }_${userData.FIRSTNAME}_${new Date().getTime()}`.replace(/ /g, "_");
    (<HTMLInputElement>document.getElementById("sessionName")).value =
      userInfo.fromId;
    (<HTMLInputElement>document.getElementById("fromId")).value =
      userInfo.fromId;
    (<HTMLInputElement>document.getElementById("toId")).value = userInfo.toId;

    (<HTMLInputElement>document.getElementById("newUserId")).value =
      userInfo.usrTkn;

    if (+userInfo.userType === 1) {
      register(
        userInfo.toId,
        userInfo.fromId,
        userInfo.name,
        userInfo.userType
      );
      statusRegister();
      sessionStorage.userInfo = JSON.stringify(userInfo);
    }
  }
}
